import { FC, ReactNode } from "react";
import { ITypography } from "./types";

const body = (children: ReactNode, className?: string) => (
  <div className={`text-base font-[350] leading-normal m-0 p-0 ${className}`}>{children}</div>
);

const Typography: FC<ITypography> = ({ variant, children, className }) => {
  const addtionalClass = className ? `${className}` : "";
  switch (variant) {
    case "H1":
      return <h1 className={`text-4xl font-black leading-[3.5rem] m-0 p-0 ${addtionalClass}`}>{children}</h1>;
    case "H2":
      return <h2 className={`text-3xl font-black leading-[3.0rem] m-0 p-0 ${addtionalClass}`}>{children}</h2>;
    case "H3":
      return <h3 className={`text-2xl font-black leading-9 m-0 p-0 ${addtionalClass}`}>{children}</h3>;
    case "H4":
      return <h4 className={`text-xl font-black leading-8 m-0 p-0 ${addtionalClass}`}>{children}</h4>;
    case "H5":
      return <h5 className={`text-lg font-semibold leading-7 m-0 p-0 ${addtionalClass}`}>{children}</h5>;
    case "H6":
      return <h6 className={`text-base font-semibold leading-6 m-0 p-0 ${addtionalClass}`}>{children}</h6>;
    case "Lead":
      return <div className={`text-base font-normal leading-[1.45] m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "Body":
      return body(children, addtionalClass);
    case "BodySpan":
      return <span className={`text-base font-[350] leading-normal m-0 p-0 ${addtionalClass}`}>{children}</span>;
    case "Italic":
      return <div className={`text-base font-[350] leading-normal italic m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "ItalicSpan":
      return <span className={`text-base font-[350] leading-normal italic m-0 p-0 ${addtionalClass}`}>{children}</span>;
    case "Bold":
      return <div className={`text-base font-normal leading-normal m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "BoldSpan":
      return <span className={`text-base font-normal leading-normal m-0 p-0 ${addtionalClass}`}>{children}</span>;
    case "Error":
      return <div className={`text-sm font-normal leading-normal m-0 p-0 text-red ${addtionalClass}`}>{children}</div>;
    case "Warning":
      return <div className={`text-sm font-normal leading-normal m-0 p-0 text-warning ${addtionalClass}`}>{children}</div>;
    case "Success":
      return <div className={`text-base font-[350] leading-normal m-0 p-0 text-green ${addtionalClass}`}>{children}</div>;
    case "Description":
      return <div className={`text-sm font-[350] leading-normal m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "DescriptionBold":
      return <div className={`text-sm font-normal leading-normal m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "DescriptionItalic":
      return <div className={`text-sm font-[350] leading-normal italic m-0 p-0 ${addtionalClass}`}>{children}</div>;
    case "DescriptionSpan":
      return <span className={`text-sm font-[350] leading-normal m-0 p-0 ${addtionalClass}`}>{children}</span>;
    case "DescriptionSmall":
      return <span className={`text-xs font-light leading-normal m-0 p-0 ${addtionalClass}`}>{children}</span>;
    default:
      return body(children, addtionalClass);
  }
};

export default Typography;
